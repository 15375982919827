import React, { useState, useEffect } from 'react';
import './Homepage.css';
import TVLDisplayComponent from '../TVL_Display/TVLDisplayComponent';
import ArticleCard from '../article_card/articleCard';
import ETHPriceDisplayComponent from '../header/ETHPriceDisplayComponent.js';

const Homepage = () => {
  const [articles, setArticles] = useState([]);
  const MAX_ARTICLES = 8;

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await fetch('/api/published-articles');
      const data = await response.json();
      
      // Sort articles by date and time, newest first
      const sortedData = [...data].sort((a, b) => {
        const dateA = new Date(a.publishedAt);
        const dateB = new Date(b.publishedAt);
        return dateB.getTime() - dateA.getTime();
      });

      // Limit to MAX_ARTICLES
      const limitedData = sortedData.slice(0, MAX_ARTICLES);

      setArticles(limitedData);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  return (
    <main className="homepage">      
      <section className="hero-section">
        <div className="hero-gradient"></div>
        <h1>Welcome to The Daily Subgraph!</h1>
      </section>

      <div className="statistics-container">
        <section className="eth-price-section">
          <h3>Current Ethereum Price</h3>
          <h4>The current price of Ethereum in USD, provided by <a href="https://basescan.org/" target="_blank" rel="noopener noreferrer">BaseScan</a>.</h4>        
          <ETHPriceDisplayComponent />
        </section>
      </div>

      <section className="latest-news">
        <h3>Latest News</h3>
        <div className="news-grid">
          {articles.map((article) => (
            <ArticleCard
              key={article.slug}
              title={article.title}
              publishedAt={article.publishedAt}
              description={article.description}
              featuredImage={article.featuredImage}
              slug={article.slug}
            />
          ))}
        </div>
      </section>
    </main>
  );
};

export default Homepage;