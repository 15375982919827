import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from './clientFirebaseConfig';

const ProtectedRouteComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User authenticated:', user.email);
        setIsAuthenticated(true);
      } else {
        console.log('No user authenticated');
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading authentication status...</div>;
  }

  if (!isAuthenticated) {
    console.log('Redirecting to login page');
    return <Navigate to="/login" />;
  }

  console.log('Rendering protected route');
  return <>{children}</>;
};

export default ProtectedRouteComponent;