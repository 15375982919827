// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Homepage from './components/homepage/Homepage';
import Login from './components/Login';
import TOS from './components/footer/TOS';
import Disclaimer from './components/footer/Disclaimer';
import Contact from './components/footer/Contact';
import AdminDashboard from './components/admin_dashboard/AdminDashboard';
import ProtectedRouteComponent from './components/ProtectedRouteComponent';
import ArticleReviewer from './components/article_reviewer/articleReviewer';
import ArticleEditorWrapper from './components/article_editor_wrapper/ArticleEditorWrapper';
import ArticleReader from './components/article_reader/articleReader';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/admin-dashboard" 
          element={
            <ProtectedRouteComponent>
              <AdminDashboard />
            </ProtectedRouteComponent>
          }
        />
        <Route 
          path="/articles" 
          element={
            <ProtectedRouteComponent>
              <ArticleReviewer />
            </ProtectedRouteComponent>
          }
        />
        <Route 
          path="/editor/:slug" 
          element={
            <ProtectedRouteComponent>
              <ArticleEditorWrapper />
            </ProtectedRouteComponent>
          }
        />
        <Route path="/article/:slug" element={<ArticlePage />} />
        <Route path="/terms-of-service" element={<TOS />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

function ArticlePage() {
  const [article, setArticle] = useState(null);
  const [twitterMetadata, setTwitterMetadata] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    fetchArticle(slug);
  }, [slug]);

  async function fetchArticle(slug) {
    try {
      const response = await fetch(`/api/published-articles/${slug}`);
      const data = await response.json();
      if (data) {
        setArticle(data.article);
        setTwitterMetadata(data.twitterMetadata);
      }
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  }

  if (!article) {
    return <div>Loading...</div>;
  }

  return <ArticleReader article={article} twitterMetadata={twitterMetadata} />;
}

export default App;