// articleEditor.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './articleEditor.css';

const API_URL = 'http://localhost:3001';

function ArticleEditor({ article: { article, isPublished }, onClose, onDelete }) {
  const [articleState, setArticleState] = useState({
    title: '',
    content: '',
    authorFirstName: '',
    slug: '',
    categories: '',
    tags: '',
    metaTitle: '',
    metaDescription: '',
    ogTitle: '',
    ogDescription: '',
    twitterTitle: '',
    twitterDescription: '',
    canonicalUrl: '',
    robotsMetaTag: '',
    isFeaturedArticle: false,
  });
  const [isPublishedState, setIsPublishedState] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('Received article prop:', article);
    if (article) {
      const newArticleState = {
        ...article.article, // Spread the article object, not the entire prop
        id: article.article.id || article.article.filename,
        categories: article.article.categories ? article.article.categories.split(',').map(category => category.trim()).join(',') : '',
        tags: article.article.tags ? article.article.tags.split(',').map(tag => tag.trim()).join(',') : '',
        isFeaturedArticle: article.article.isFeaturedArticle === 'true',
      };
      setArticleState(newArticleState);
      console.log('Updated article state:', newArticleState);
      setIsLoading(false);
    }
    setIsPublishedState(isPublished || false);
  }, [article, isPublished]);

  const handleInputChange = (event) => {
    const { id, value, type, checked } = event.target;
    
    setArticleState(prevArticle => ({
      ...prevArticle,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileUpload = (event) => {
    setFeaturedImage(event.target.files[0]);
    setUploadedImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmitArticle = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('featuredImage', featuredImage);
  
      // Convert boolean values to strings
      formData.set('isFeaturedArticle', articleState.isFeaturedArticle.toString());
  
      // Ensure we're sending the correct ID
      const articleId = articleState.id || articleState.filename;
      if (!articleId) {
        throw new Error("Article ID is missing");
      }
  
      let url;
      if (isPublishedState) {
        url = `${API_URL}/api/edit-published-article/${articleId}`;
      } else {
        url = `${API_URL}/api/edit-article/${articleId}`;
      }
  
      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setArticleState(response.data);
    } catch (error) {
      console.error('Error submitting article:', error.response?.data || error.message);
    }
  };
  
  const handleClose = () => {
    onClose();
  };
  
  const handlePublishArticle = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/publish-article/${articleState.id}`);
      
      if (response.status === 200) {
        setIsPublishedState(true);
        setArticleState(prevArticle => ({
          ...prevArticle,
          publishedAt: new Date().toISOString()
        }));
  
        // Show a success message to the user
        alert('Article published successfully!');
        
        // Optionally, you could refresh the page or navigate to the published article
        // window.location.reload(); // Uncomment if you want to reload the page
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error publishing article:', error.response?.data || error.message);
      alert('Failed to publish article. Please try again.');
    }
  };

  const handleDeleteArticle = async () => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        const response = await axios.delete(`${API_URL}/api/delete-article/${articleState.id}`);
  
        if (response.status === 200) {
          // Inform the user about successful deletion
          alert('Article deleted successfully!');
  
          // Call the onDelete callback
          onDelete();
  
          // Optionally, you can reset the article state
          setArticleState({
            title: '',
            content: '',
            authorFirstName: '',
            slug: '',
            categories: '',
            tags: '',
            metaTitle: '',
            metaDescription: '',
            ogTitle: '',
            ogDescription: '',
            twitterTitle: '',
            twitterDescription: '',
            canonicalUrl: '',
            robotsMetaTag: '',
            isFeaturedArticle: false,
          });
  
          // Reset other states if necessary
          setIsPublishedState(false);
          setFeaturedImage(null);
          setUploadedImageUrl('');
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        console.error('Error deleting article:', error.response?.data || error.message);
        alert('Failed to delete article. Please try again.');
      }
    }
  };
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log('Rendering ArticleEditor with state:', articleState);
  return (
    <div className="article-editor-container">
      <div className="window-header">
        <h3>{isPublishedState ? 'Edit Published Article' : 'Edit Draft Article'}</h3>
        <button onClick={handleClose} className="close-button">Close</button>
        {isPublishedState && (
          <button onClick={handleDeleteArticle} className="delete-button">Delete</button>
        )}
      </div>
      <form onSubmit={handleSubmitArticle} encType="multipart/form-data" className="scrollable-content">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input type="text" id="title" name="title" value={articleState.title} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="content">Content:</label>
          <textarea id="content" rows="5" name="content" value={articleState.content} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="authorFirstName">Author First Name:</label>
          <input type="text" id="authorFirstName" name="authorFirstName" value={articleState.authorFirstName} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="slug">Slug:</label>
          <input type="text" id="slug" name="slug" value={articleState.slug} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="categories">Categories:</label>
          <input type="text" id="categories" name="categories" value={articleState.categories} onChange={handleInputChange} placeholder="Comma-separated categories" required />
        </div>

        <div className="form-group">
          <label htmlFor="tags">Tags:</label>
          <input type="text" id="tags" name="tags" value={articleState.tags} onChange={handleInputChange} placeholder="Comma-separated tags" required />
        </div>

        <div className="form-group">
          <label htmlFor="featuredImage">Upload Featured Image:</label>
          <input type="file" id="featuredImage" name="featuredImage" accept=".jpg,.png" onChange={handleFileUpload} required />
          {uploadedImageUrl && <img src={uploadedImageUrl} alt="" style={{ width: '100px', height: 'auto' }} />}
        </div>

        <div className="form-group">
          <label htmlFor="metaTitle">Meta Title:</label>
          <input type="text" id="metaTitle" name="metaTitle" value={articleState.metaTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="metaDescription">Meta Description:</label>
          <textarea id="metaDescription" rows="2" name="metaDescription" value={articleState.metaDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="ogTitle">OpenGraph Title:</label>
          <input type="text" id="ogTitle" name="ogTitle" value={articleState.ogTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="ogDescription">OpenGraph Description:</label>
          <textarea id="ogDescription" rows="2" name="ogDescription" value={articleState.ogDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="twitterTitle">Twitter Title:</label>
          <input type="text" id="twitterTitle" name="twitterTitle" value={articleState.twitterTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="twitterDescription">Twitter Description:</label>
          <textarea id="twitterDescription" rows="2" name="twitterDescription" value={articleState.twitterDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="canonicalUrl">Canonical URL:</label>
          <input type="url" id="canonicalUrl" name="canonicalUrl" value={articleState.canonicalUrl} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="robotsMetaTag">Robots Meta Tag:</label>
          <input type="text" id="robotsMetaTag" name="robotsMetaTag" value={articleState.robotsMetaTag} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="isFeaturedArticle">Is Featured Article:</label>
          <input
            type="checkbox"
            id="isFeaturedArticle"
            name="isFeaturedArticle"
            checked={articleState.isFeaturedArticle}
            onChange={handleInputChange}
          />
        </div>

        <div className="button-group">
          <button type="submit" className="btn btn-primary">Save</button>
          {!isPublishedState && (
            <button type="button" onClick={handlePublishArticle} className="btn btn-success">Publish</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default ArticleEditor;
