// src/components/footer/Contact.js

import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-info">
      <h2>Contact Us</h2>
      <p>We'd love to hear from you! Reach out to us through one of the following channels:</p>
      <ul>
        <li>
          <strong>Email:</strong> <a href="mailto:admin@dailysubgraph.com">admin@dailysubgraph.com</a>
        </li>
        <li>
          <strong>X (Twitter):</strong> <a href="https://twitter.com/dailysubgraph" target="_blank" rel="noopener noreferrer">@dailysubgraph</a>
        </li>
      </ul>
    </div>
  );
};

export default Contact;