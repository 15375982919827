// articleForm.js
import React, { useState } from 'react';

const ArticleForm = ({ onSubmit, onClose }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [authorFirstName, setAuthorFirstName] = useState('');
  const [slug, setSlug] = useState('');
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [ogTitle, setOgTitle] = useState('');
  const [ogDescription, setOgDescription] = useState('');
  const [twitterTitle, setTwitterTitle] = useState('');
  const [twitterDescription, setTwitterDescription] = useState('');
  const [canonicalUrl, setCanonicalUrl] = useState('');
  const [robotsMetaTag, setRobotsMetaTag] = useState('');
  const [isFeaturedArticle, setIsFeaturedArticle] = useState(false);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(`Input ${id} changed: ${value}`);
    
    switch(id) {
      case 'isFeaturedArticle':
        setIsFeaturedArticle(prevState => !prevState);
        console.log('New isFeaturedArticle state:', isFeaturedArticle);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'content':
        setContent(value);
        break;
      case 'authorFirstName':
        setAuthorFirstName(value);
        break;
      case 'slug':
        setSlug(value);
        break;
      case 'categories':
        setCategories(value.split(','));
        break;
      case 'tags':
        setTags(value.split(','));
        break;
      case 'metaTitle':
        setMetaTitle(value);
        break;
      case 'metaDescription':
        setMetaDescription(value);
        break;
      case 'ogTitle':
        setOgTitle(value);
        break;
      case 'ogDescription':
        setOgDescription(value);
        break;
      case 'twitterTitle':
        setTwitterTitle(value);
        break;
      case 'twitterDescription':
        setTwitterDescription(value);
        break;
      case 'canonicalUrl':
        setCanonicalUrl(value);
        break;
      case 'robotsMetaTag':
        setRobotsMetaTag(value);
        break;
      default:
        console.log('Unhandled input:', id);
    }
  };

  const handleFileUpload = (event) => {
    setFeaturedImage(event.target.files[0]);
    setUploadedImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);

      // Convert boolean values to strings
      formData.set('isFeaturedArticle', isFeaturedArticle.toString());

      console.log('Sending form data:', Object.fromEntries(formData));

      const response = await onSubmit(formData);

      console.log('Article submitted successfully:', response.data);
      resetForm();

    } catch (error) {
      console.error('Error submitting article:', error.response?.data || error.message);
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setAuthorFirstName('');
    setSlug('');
    setCategories([]);
    setTags([]);
    setFeaturedImage(null);
    setUploadedImageUrl('');
    setMetaTitle('');
    setMetaDescription('');
    setOgTitle('');
    setOgDescription('');
    setTwitterTitle('');
    setTwitterDescription('');
    setCanonicalUrl('');
    setRobotsMetaTag('');
    setIsFeaturedArticle(false);
  };

  return (
    <div className="scrollable-window">
      <div className="window-header">
        <h3>Submit New Article</h3>
        <button onClick={onClose}>Close</button>
      </div>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="scrollable-content">
      <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input type="text" id="title" name="title" value={title} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="content">Content:</label>
          <textarea id="content" rows="5" name="content" value={content} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="authorFirstName">Author First Name:</label>
          <input type="text" id="authorFirstName" name="authorFirstName" value={authorFirstName} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="slug">Slug:</label>
          <input type="text" id="slug" name="slug" value={slug} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="categories">Categories:</label>
          <input type="text" id="categories" name="categories" value={categories.join(',')} onChange={handleInputChange} placeholder="Comma-separated categories" required />
        </div>

        <div className="form-group">
          <label htmlFor="tags">Tags:</label>
          <input type="text" id="tags" name="tags" value={tags.join(',')} onChange={handleInputChange} placeholder="Comma-separated tags" required />
        </div>

        <div className="form-group">
          <label htmlFor="featuredImage">Upload Featured Image:</label>
          <input type="file" id="featuredImage" name="featuredImage" accept=".jpg,.png" onChange={handleFileUpload} required />
          {uploadedImageUrl && <img src={uploadedImageUrl} alt="Uploaded Image" style={{ width: '100px', height: 'auto' }} />}
        </div>

        <div className="form-group">
          <label htmlFor="metaTitle">Meta Title:</label>
          <input type="text" id="metaTitle" name="metaTitle" value={metaTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="metaDescription">Meta Description:</label>
          <textarea id="metaDescription" rows="2" name="metaDescription" value={metaDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="ogTitle">OpenGraph Title:</label>
          <input type="text" id="ogTitle" name="ogTitle" value={ogTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="ogDescription">OpenGraph Description:</label>
          <textarea id="ogDescription" rows="2" name="ogDescription" value={ogDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="twitterTitle">Twitter Title:</label>
          <input type="text" id="twitterTitle" name="twitterTitle" value={twitterTitle} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="twitterDescription">Twitter Description:</label>
          <textarea id="twitterDescription" rows="2" name="twitterDescription" value={twitterDescription} onChange={handleInputChange} required></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="canonicalUrl">Canonical URL:</label>
          <input type="url" id="canonicalUrl" name="canonicalUrl" value={canonicalUrl} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="robotsMetaTag">Robots Meta Tag:</label>
          <input type="text" id="robotsMetaTag" name="robotsMetaTag" value={robotsMetaTag} onChange={handleInputChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="isFeaturedArticle">Is Featured Article:</label>
          <input 
            type="checkbox" 
            id="isFeaturedArticle" 
            name="isFeaturedArticle" 
            checked={isFeaturedArticle} 
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit Article</button>
      </form>
    </div>
  );
};

export default ArticleForm;