// Client/clientFirebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCv2Qb1rJ2pkrfGdS7-xhoEX_DQIGFei4s",
  authDomain: "the-base-daily-subgraph.firebaseapp.com",
  projectId: "the-base-daily-subgraph",
  storageBucket: "the-base-daily-subgraph.appspot.com",
  messagingSenderId: "629162731854",
  appId: "1:629162731854:web:fe2d1acc6fcc3e56ef561f",
  measurementId: "G-9T11VFFLKD"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);