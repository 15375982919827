import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ETHPriceDisplayComponent.css';

function ETHPriceDisplayComponent() {
  const [price, setPrice] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const priceRef = useRef(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get('/api/eth-price');
        const data = response.data;
        setPrice(parseFloat(data.ethPrice.replace('$', ''))); // Convert string to float
        setLastUpdated(new Date(data.lastUpdated));
        setLoading(false);
      } catch (error) {
        console.error('Fetch Error:', error);
        setError('Failed to fetch ETH Price data');
        setLoading(false);
      }
    };

    fetchPrice();

    const intervalId = setInterval(fetchPrice, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (typeof price === 'number' && price >= 0 && priceRef.current) {
      countUpAnimation(price);
    }
  }, [price]);

  const countUpAnimation = (finalValue) => {
    const startValue = 0;
    const duration = 5000; // Animation duration in milliseconds
    const increment = Math.ceil(finalValue / (duration / 100));

    let currentValue = startValue;
    priceRef.current.textContent = `$${currentValue.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;

    const intervalId = setInterval(() => {
      currentValue += increment;
      if (currentValue > finalValue) {
        clearInterval(intervalId);
        priceRef.current.textContent = `$${finalValue.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
      } else {
        priceRef.current.textContent = `$${currentValue.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
      }
    }, 16); // Approximately 60 times per second
  };

  if (loading) {
    return (
      <div className="loading-container">
        <p className="loading-text">Loading ETH Price...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  if (!price || !lastUpdated) {
    return <div className="error-container">No ETH Price data available.</div>;
  }

  return (
    <div className="display-component">
      <p ref={priceRef} className="value">$0</p>
    </div>
  );
}

export default ETHPriceDisplayComponent;