import React from 'react';
import moment from 'moment';
import './articleReader.css';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import { Helmet } from 'react-helmet';

function ArticleReader({ article, twitterMetadata }) {
  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM D, YYYY h:mm A');
  };

  const metaTags = [
    { name: 'title', content: article.title },
    { name: 'description', content: article.metaDescription },
    { property: 'og:title', content: article.ogTitle },
    { property: 'og:description', content: article.ogDescription },
    { property: 'og:image', content: article.featuredImage },
    { property: 'og:url', content: window.location.href },
    { property: 'og:type', content: 'article' },
  ];

  if (twitterMetadata) {
    metaTags.push(
      { name: 'twitter:card', content: twitterMetadata.card },
      { name: 'twitter:site', content: twitterMetadata.site },
      { name: 'twitter:creator', content: twitterMetadata.creator },
      { name: 'twitter:title', content: twitterMetadata.title },
      { name: 'twitter:description', content: twitterMetadata.description },
      { name: 'twitter:image', content: twitterMetadata.image }
    );
  }

  return (
    <div className="article-reader">
      <Helmet>
        {metaTags.map((tag, index) => (
          <meta key={index} name={tag.name || tag.property} content={tag.content} />
        ))}
      </Helmet>
      <h1>{article.title}</h1>
      <p>By {article.authorFirstName}</p>
      <p>Published: {formatDate(article.publishedAt)}</p>
      <img src={article.featuredImage} alt={article.title} className="featured-image" />
      
      <ReactMarkdown 
        rehypePlugins={[rehypeSlug, [rehypeAutolinkHeadings, { behavior: "wrap" }]]}
      >
        {article.content}
      </ReactMarkdown>

      <div className="article-meta">
        <p>Categories: {article.categories.split(',').map(cat => cat.trim()).join(', ')}</p>
        <p>Tags: {article.tags.split(',').map(tag => tag.trim()).join(', ')}</p>
      </div>
    </div>
  );
}

export default ArticleReader;