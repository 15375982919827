// ArticleSubmissionForm.js
import React from 'react';
import axios from 'axios';
import ArticleForm from '../article-form/articleForm'; // Make sure this import path is correct
import './ArticleSubmissionForm.css'
function ArticleSubmissionForm({ onClose }) {
  const handleSubmit = async (formData) => {
    const response = await axios.post('/api/submit-article', formData);
    return response;
  };

  return (
    <ArticleForm onSubmit={handleSubmit} onClose={onClose} />
  );
}

export default ArticleSubmissionForm;