import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../clientFirebaseConfig';
import ArticleSubmissionForm from '../article_submission_form/ArticleSubmissionForm';
import './AdminDashboard.css';
import ArticleReviewer from '../article_reviewer/articleReviewer';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isReviewArticlesOpen, setIsReviewArticlesOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  const handleToggleReviewArticles = () => {
    setIsReviewArticlesOpen(!isReviewArticlesOpen);
  };

  if (!isLoggedIn) {
    return <div>Not logged in. Please log in.</div>;
  }

  return (
    <div className="admin-dashboard">
      <div className="button-container">
        {!showForm && (
          <button onClick={handleToggleForm}>New Article</button>
        )}
        {showForm && (
          <ArticleSubmissionForm onClose={() => setShowForm(false)} />
        )}
      </div>

      <div className="button-container">
  {!isReviewArticlesOpen && (
    <button onClick={handleToggleReviewArticles}>Review Articles</button>
  )}
  {isReviewArticlesOpen && (
    <div className="scrollable-window">
      <ArticleReviewer isOpen={() => setIsReviewArticlesOpen(false)} />
    </div>
  )}
</div>

      <div className="button-container">
        <button onClick={() => {
          auth.signOut().then(() => {
            navigate('/login');
          });
        }}>Logout</button>
      </div>
    </div>
  );
};

export default AdminDashboard;