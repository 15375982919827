import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ArticleEditor from '../article_editor/articleEditor.js';
import './ArticleEditorWrapper.css'

const API_URL = 'http://localhost:3001';

const fetchArticle = async (slug, setArticle, setError, setIsLoading) => {
  console.log('Fetching article with slug:', slug);
  setIsLoading(true);
  setError(null);

  try {
    // Try to fetch the draft article first
    const draftResponse = await axios.get(`${API_URL}/api/articles/${slug}`);
    console.log('Fetched draft article:', draftResponse.data);
    setArticle({ article: draftResponse.data, isPublished: false });
  } catch (draftError) {
    console.error('Error fetching draft article:', draftError);
    
    // If draft not found, try to fetch published article
    try {
      const publishedResponse = await axios.get(`${API_URL}/api/published-articles/${slug}`);
      console.log('Fetched published article:', publishedResponse.data);
      setArticle({ article: publishedResponse.data, isPublished: true });
    } catch (publishedError) {
      console.error('Error fetching published article:', publishedError);
      setError('Failed to fetch article. Please try again later.');
      setArticle(null);
    }
  } finally {
    setIsLoading(false);
  }
};

function ArticleEditorWrapper() {
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    console.log('Component mounted or slug changed:', slug);
    fetchArticle(slug, setArticle, setError, setIsLoading);
  }, [slug]);

  if (isLoading) {
    console.log('Rendering loading state');
    return <div>Loading...</div>;
  }

  if (error) {
    console.log('Rendering error:', error);
    return <div>{error}</div>;
  }

  if (!article || Object.keys(article).length === 0) {
    console.log('Article not found or empty');
    return <div>Article not found or empty</div>;
  }

  console.log('Article data being passed to ArticleEditor:', article);
  return (
    <ArticleEditor 
      article={{ article: article.article, isPublished: article.isPublished }}
      onClose={() => window.history.back()} 
      onDelete={() => fetchArticle(slug, setArticle, setError, setIsLoading)}
    />
  );
}

export default ArticleEditorWrapper;