// src/components/header/Header.js
import React, { useState, useEffect } from 'react';
import fallbackLogo from '../../assets/fallbacklogo.png';
import './Header.css';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const cities = [
  { name: 'Los Angeles', timezone: 'America/Los_Angeles' },
  { name: 'Tokyo', timezone: 'Asia/Tokyo' },
  { name: 'Singapore', timezone: 'Asia/Singapore' },
  { name: 'Berlin', timezone: 'Europe/Berlin' },
  { name: 'Dubai', timezone: 'Asia/Dubai' },
  { name: 'London', timezone: 'Europe/London' },
  { name: 'New York', timezone: 'America/New_York' },
  { name: 'Moscow', timezone: 'Europe/Moscow' }, // Russia
  { name: 'Beijing', timezone: 'Asia/Shanghai' }, // China
  { name: 'Sydney', timezone: 'Australia/Sydney' }, // Australia
  { name: 'Cairo', timezone: 'Africa/Cairo' }, // Egypt
  { name: 'Mumbai', timezone: 'Asia/Kolkata' }, // India
  { name: 'Johannesburg', timezone: 'Africa/Johannesburg' }, // South Africa
  { name: 'Rio de Janeiro', timezone: 'America/Sao_Paulo' }, // Brazil
];

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 500);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    };
    return date.toLocaleDateString(undefined, options);
  };

  const getTimeForCity = (city) => {
    return moment(currentTime).tz(city.timezone).format('h:mm:ss A');
  };

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/" className="logo-link">
          <img src={fallbackLogo} alt="The Base Telegraph Logo" className="logo" />
        </Link>
      </div>
      <div className="time-date-container">
        <span>{formatDate(currentTime)}</span>
        <span> {currentTime.toLocaleTimeString()}</span>
      </div>
      <div className="world-time-container">
        <div className="world-time-ticker">
          {cities.map((city, index) => (
            <span key={index}>{city.name}:{getTimeForCity(city)}</span>
          ))}
          {/* Duplicate cities to create seamless loop */}
          {cities.map((city, index) => (
            <span key={`duplicate-${index}`}>{city.name}: {getTimeForCity(city)}</span>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;