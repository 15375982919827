import React from 'react';
import './Disclaimer.css';


const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <h2>Disclaimer</h2>
      <p>The information provided by The Daily Subgraph or The Base Daily Subgraph ("we," "us," or "our") on [dailysubgraph.com] (the "Site") is for general informational purposes only. All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>

      <h3>Not Financial Advice</h3>
      <p>The Site does not contain financial, investment, or other professional advice. The content provided is for informational and educational purposes only. You should not construe any such information or other material as financial, legal, tax, or other advice. Nothing contained on our Site constitutes a solicitation, recommendation, endorsement, or offer by [The Daily Subgraph] or any third party to buy or sell any securities or other financial instruments in any jurisdiction.</p>
      <p>Please consult with a qualified financial, legal, or tax professional before making any decisions based on the information provided.</p>

      <h3>No Responsibility for Loss</h3>
      <p>Under no circumstances shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on the Site is solely at your own risk.</p>

      <h3>Third-Party Links and Information</h3>
      <p>The Site may contain links to third-party websites or content originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>

      <h3>Investment Risks</h3>
      <p>The prices of digital assets are extremely volatile and may be affected by external factors such as financial, regulatory, or political events. Digital assets are also subject to risks such as hacking, fraud, or regulatory scrutiny, which could result in substantial losses. Past performance is not indicative of future results. You are solely responsible for evaluating the merits and risks associated with the use of any information or other content on the Site before making any decisions based on such information.</p>

      <h3>No Warranties</h3>
      <p>The information on this Site is provided on an "as-is" basis with no guarantees of completeness, accuracy, or timeliness and without any warranties of any kind, express or implied. [Your Website Name] does not warrant that this Site, its servers, or any email sent from us are free of viruses or other harmful components.</p>
    </div>
  );
};

export default Disclaimer;