import React from 'react';
import moment from 'moment'; // Make sure to import moment
import './articleCard.css'

const ArticleCard = ({ title, author, publishedAt, description, featuredImage, slug }) => {
  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM D, YYYY');
  };

  return (
    <div className="card">
      <img src={featuredImage} alt={title} className="card-img-top" />
      <div className="card-body">
        <a href={`/article/${slug}`} className="btn btn-primary">Read More</a>
        <h2 className="card-title">{title}</h2>
        <p className="card-date">{formatDate(publishedAt)}</p>
        <p className="card-description">{description}</p>
      </div>
    </div>
  );
};

export default ArticleCard;