import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './articleReviewer.css'

function ArticleReviewer({ isOpen }) {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showPublished, setShowPublished] = useState(false);

  useEffect(() => {
    const fetchAllArticles = async () => {
      try {
        const [regularResponse, publishedResponse] = await Promise.all([
          fetch('/api/articles'),
          fetch('/api/published-articles')
        ]);

        if (!regularResponse.ok || !publishedResponse.ok) {
          throw new Error('Failed to fetch articles');
        }

        const regularData = await regularResponse.json();
        const publishedData = await publishedResponse.json();

        // Mark published articles as such
        const markedPublishedData = publishedData.map(article => ({ ...article, isPublished: true }));

        // Combine regular and published articles
        const allArticles = [...regularData, ...markedPublishedData];

        setArticles(allArticles);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchAllArticles();
  }, []);

  const navigateToEditor = (slug) => {
    navigate(`/editor/${slug}`);
  };

  if (isLoading) {
    return <div>Loading articles...</div>;
  }

  if (error) {
    return <div>Error loading articles: {error}</div>;
  }

  if (!isOpen || !articles || articles.length === 0) {
    return <div>No articles found.</div>;
  }

  const filteredArticles = showPublished ? 
    articles.filter(article => article.isPublished) :
    articles.filter(article => !article.isPublished);

  return (
    <div className="article-reviewer-container">
      <h1 className="header">Article Viewer</h1>
      <button onClick={() => isOpen(false)} className="close-button">Close</button>
      <button onClick={() => setShowPublished(prev => !prev)}>
        {showPublished ? 'Show Drafts' : 'Show Published'}
      </button>
      <div className="article-list">
        <ul className="article-list-ul">
          {filteredArticles.map((article, index) => (
            <li key={index} className={`article-item ${article.isPublished ? 'published' : ''}`}>
              <img
                src={article.featuredImage}
                alt={`Thumbnail for ${article.title}`}
                className="thumbnail"
              />
              <div className="article-content">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
                <button 
                  onClick={() => navigateToEditor(article.slug)}
                  className="open-article-editor-button"
                >
                  Open Editor
                </button>
                <button 
                  onClick={() => handleDeleteArticle(article)}
                  className="delete-article-button"
                >
                  Delete Article
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const handleDeleteArticle = async (article) => {
  if (window.confirm(`Are you sure you want to delete "${article.title}"?`)) {
    try {
      const articleId = article.id || article.filename;
      let url;
      if (article.isPublished) {
        url = `/api/delete-published-article/${articleId}`;
      } else {
        url = `/api/delete-article/${articleId}`;
      }

      const response = await fetch(url, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete article');
      }

      // Refresh the articles list
      window.location.reload();
    } catch (error) {
      console.error('Error deleting article:', error);
      alert('Failed to delete article. Please try again.');
    }
  }
};

export default ArticleReviewer;