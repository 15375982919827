import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './clientFirebaseConfig';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/admin-dashboard');
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginSuccess(false);
    setLoginError(null);

    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      console.log('Firebase Auth response:', response);
      setIsLoggedIn(true);
      setLoginSuccess(true);
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError(error.message);
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Welcome Back</h2>
      
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Enter your password"
            required
          />
        </div>

        <button type="submit" className="submit-button">Log In</button>
        
        {loginSuccess && (
          <p className="success-message">Login successful! Redirecting...</p>
        )}
        {loginError && (
          <p className="error-message">{loginError}</p>
        )}
      </form>
    </div>
  );
};

export default Login;