// src/components/footer/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="copyright">
          &copy; {new Date().getFullYear()} The Daily Subgraph. All rights reserved.
        </div>
        <div className="social-links">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      
      <nav>
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/disclaimer">Disclaimer</Link>
        <Link to="/contact">Contact Us</Link> {/* Added contact link */}
      </nav>
    </footer>
  );
};

export default Footer;